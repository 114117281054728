import a1 from 'images/products/a/a1.jpg';
import a2 from 'images/products/a/a2.jpg';
import a3 from 'images/products/a/a3.jpg';
import a4 from 'images/products/a/a4.jpg';
import a5 from 'images/products/a/a5.jpg';
import a6 from 'images/products/a/a6.jpg';
import a7 from 'images/products/a/a7.jpg';
import a8 from 'images/products/a/a8.jpg';
import a9 from 'images/products/a/a9.jpg';
import a10 from 'images/products/a/a10.jpg';

import b1 from 'images/products/b/b1.jpg';
import b2 from 'images/products/b/b2.jpg';
import b3 from 'images/products/b/b3.jpg';
import b4 from 'images/products/b/b4.jpg';
import b5 from 'images/products/b/b5.jpg';
import b7 from 'images/products/b/b7.jpg';
import b8 from 'images/products/b/b8.jpg';
import b9 from 'images/products/b/b9.jpg';
import b10 from 'images/products/b/b10.jpg';

import c1 from 'images/products/c/c1.jpg';
import c2 from 'images/products/c/c2.jpg';
import c3 from 'images/products/c/c3.jpg';
import c4 from 'images/products/c/c4.jpg';
import c5 from 'images/products/c/c5.jpg';
import c6 from 'images/products/c/c6.jpg';
import c7 from 'images/products/c/c7.jpg';
import c8 from 'images/products/c/c8.jpg';
import c9 from 'images/products/c/c9.jpg';
import c10 from 'images/products/c/c10.jpg';

import d1 from 'images/products/d/d1.jpg';
import d2 from 'images/products/d/d2.jpg';
import d3 from 'images/products/d/d3.jpg';
import d4 from 'images/products/d/d4.jpg';
import d5 from 'images/products/d/d5.jpg';
import d6 from 'images/products/d/d6.jpg';
import d7 from 'images/products/d/d7.jpg';
import d8 from 'images/products/d/d8.jpg';
import d9 from 'images/products/d/d9.jpg';

import e1 from 'images/products/e/e1.jpg';
import e2 from 'images/products/e/e2.jpg';
import e3 from 'images/products/e/e3.jpg';
import e4 from 'images/products/e/e4.jpg';

import f1 from 'images/products/f/f1.jpg';
import f2 from 'images/products/f/f2.jpg';
import f3 from 'images/products/f/f3.jpg';
import f4 from 'images/products/f/f4.jpg';

import g1 from 'images/products/g/g1.jpg';
import g2 from 'images/products/g/g2.jpg';
import g3 from 'images/products/g/g3.jpg';
import g4 from 'images/products/g/g4.jpg';
import g5 from 'images/products/g/g5.jpg';

import h1 from 'images/products/h/h1.jpg';
import h2 from 'images/products/h/h2.jpg';
import h3 from 'images/products/h/h3.jpg';
import h4 from 'images/products/h/h4.jpg';
import h5 from 'images/products/h/h5.jpg';
import h6 from 'images/products/h/h6.jpg';
import h7 from 'images/products/h/h7.jpg';
import h8 from 'images/products/h/h8.jpg';
import h9 from 'images/products/h/h9.jpg';
import h10 from 'images/products/h/h10.jpg';

import m1 from 'images/products/m/m1.jpg';
import m2 from 'images/products/m/m2.jpg';
import m3 from 'images/products/m/m3.jpg';
import m4 from 'images/products/m/m4.jpg';
import m5 from 'images/products/m/m5.jpg';
import m6 from 'images/products/m/m6.jpg';
import m7 from 'images/products/m/m7.jpg';
import m8 from 'images/products/m/m8.jpg';
import m9 from 'images/products/m/m9.jpg';
import m10 from 'images/products/m/m10.jpg';

import n1 from 'images/products/n/n1.jpg';
import n2 from 'images/products/n/n2.jpg';
import n3 from 'images/products/n/n3.jpg';
import n4 from 'images/products/n/n4.jpg';
import n5 from 'images/products/n/n5.jpg';
import n6 from 'images/products/n/n6.jpg';
import n7 from 'images/products/n/n7.jpg';
import n8 from 'images/products/n/n8.jpg';
import n9 from 'images/products/n/n9.jpg';

import o1 from 'images/products/o/o1.jpg';
import o2 from 'images/products/o/o2.jpg';
import o3 from 'images/products/o/o3.jpg';
import o4 from 'images/products/o/o4.jpg';
import o5 from 'images/products/o/o5.jpg';

import p1 from 'images/products/p/p1.jpg';
import p2 from 'images/products/p/p2.jpg';
import p3 from 'images/products/p/p3.jpg';
import p4 from 'images/products/p/p4.jpg';
import p5 from 'images/products/p/p5.jpg';

import r1 from 'images/products/r/r1.jpg';
import r2 from 'images/products/r/r2.jpg';
import r3 from 'images/products/r/r3.jpg';
import r4 from 'images/products/r/r4.jpg';
import r5 from 'images/products/r/r5.jpg';
import r6 from 'images/products/r/r6.jpg';
import r7 from 'images/products/r/r7.jpg';
import r8 from 'images/products/r/r8.jpg';
import r9 from 'images/products/r/r9.jpg';
import r10 from 'images/products/r/r10.jpg';

import s1 from 'images/products/s/s1.jpg';
import s2 from 'images/products/s/s2.jpg';
import s3 from 'images/products/s/s3.jpg';
import s4 from 'images/products/s/s4.jpg';
import s5 from 'images/products/s/s5.jpg';
import s6 from 'images/products/s/s6.jpg';

import v1 from 'images/products/v/v1.jpg';
import v2 from 'images/products/v/v2.jpg';
import v3 from 'images/products/v/v3.jpg';
import v4 from 'images/products/v/v4.jpg';
import v5 from 'images/products/v/v5.jpg';
import v6 from 'images/products/v/v6.jpg';
import v7 from 'images/products/v/v7.jpg';
import v8 from 'images/products/v/v8.jpg';
import v9 from 'images/products/v/v9.jpg';
import v10 from 'images/products/v/v10.jpg';


import k1 from 'images/products/k/k1.jpg';
import k2 from 'images/products/k/k2.jpg';
import k3 from 'images/products/k/k3.jpg';
import k4 from 'images/products/k/k4.jpg';
import k5 from 'images/products/k/k5.jpg';
import k6 from 'images/products/k/k6.jpg';
import k7 from 'images/products/k/k7.jpg';
import k8 from 'images/products/k/k8.jpg';
import k9 from 'images/products/k/k9.jpg';
import k10 from 'images/products/k/k10.jpg';

import x1 from 'images/products/x/x1.jpg';
import x2 from 'images/products/x/x2.jpg';
import x3 from 'images/products/x/x3.jpg';
import x4 from 'images/products/x/x4.jpg';
import x5 from 'images/products/x/x5.jpg';
import x6 from 'images/products/x/x6.jpg';
import x7 from 'images/products/x/x7.jpg';
import x8 from 'images/products/x/x8.jpg';
import x9 from 'images/products/x/x9.jpg';
import x10 from 'images/products/x/x10.jpg';

import q1 from 'images/products/q/q1.jpg';
import q2 from 'images/products/q/q2.jpg';
import q3 from 'images/products/q/q3.jpg';
import q4 from 'images/products/q/q4.jpg';
import q5 from 'images/products/q/q5.jpg';
import q6 from 'images/products/q/q6.jpg';
import q7 from 'images/products/q/q7.jpg';
import q8 from 'images/products/q/q8.jpg';
import q9 from 'images/products/q/q9.jpg';
import q10 from 'images/products/q/q10.jpg';

import y1 from 'images/products/y/y1.jpg';
import y2 from 'images/products/y/y2.jpg';
import y3 from 'images/products/y/y3.jpg';
import y4 from 'images/products/y/y4.jpg';
import y5 from 'images/products/y/y5.jpg';
import y6 from 'images/products/y/y6.jpg';
import y7 from 'images/products/y/y7.jpg';
import y8 from 'images/products/y/y8.jpg';
import y9 from 'images/products/y/y9.jpg';
import y10 from 'images/products/y/y10.jpg';

import z1 from 'images/products/z/z1.jpg';
import z2 from 'images/products/z/z2.jpg';
import z3 from 'images/products/z/z3.jpg';
import z4 from 'images/products/z/z4.jpg';
import z5 from 'images/products/z/z5.jpg';
import z6 from 'images/products/z/z6.jpg';
import z7 from 'images/products/z/z7.jpg';
import z8 from 'images/products/z/z8.jpg';
import z9 from 'images/products/z/z9.jpg';
import z10 from 'images/products/z/z10.jpg';

import t1 from 'images/products/t/t1.jpg';
import t2 from 'images/products/t/t2.jpg';
import t3 from 'images/products/t/t3.jpg';
import t4 from 'images/products/t/t4.jpg';
import t5 from 'images/products/t/t5.jpg';
import t6 from 'images/products/t/t6.jpg';
import t7 from 'images/products/t/t7.jpg';
import t8 from 'images/products/t/t8.jpg';
import t9 from 'images/products/t/t9.jpg';


export class Product {
  constructor(name, imgPaths) {
    this.name = name;
    this.imgPaths = imgPaths;
  }
}

export const productList = [
  new Product('Whispering Waves', [z1, z2, z3, z4, z5, z6, z7, z8, z9, z10]),
  new Product('Timber Tide II', [k1, k2, k3, k4, k5, k6, k7, k8, k9, k10]),
  new Product('Dust Flow', [n1, n2, n3, n4, n5,n6,n7,n8,n9]),
  new Product('Wave Form', [t1, t2, t3, t4, t5, t6, t7, t8, t9]),
  new Product('Timber Tide', [b1, b2, b3, b4, b5, b7, b8, b9, b10]),
  new Product('Ripple Craft', [e1, e2, e3, e4]),
  new Product('Oceanic Oak', [y1, y2, y3, y4, y5, y6, y7, y8, y9, y10]),
  new Product('Timber Tear', [a1, a2, a3, a4, a5,a6,a7,a8,a9,a10]),
  new Product('Acoustic Form', [f1, f2, f3, f4]),
  new Product('Parawood Perspectives ', [m1, m2, m3, m4, m5, m6, m7, m8, m9, m10]),
  new Product('Timber Drop', [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10]),
  new Product('Surf Flow', [g1, g2, g3, g4, g5]),
  new Product('Timber Flow ', [h1, h2, h3, h4, h5, h6, h7, h8, h9, h10]),
  new Product('Wood Whirl', [o1, o2, o3, o4, o5]),
  new Product('Timber Drift', [q1, q2, q3, q4, q5, q6, q7, q8, q9, q10]),
  new Product('Wooden Wave', [d1, d2, d3, d4, d5,d6,d7,d8,d9]),
  new Product('WoodWarp Artistry', [p1, p2, p3, p4, p5]),
  new Product('Timber Cascade', [v1, v2, v3, v4, v5, v6, v7, v8, v9, v10]),
  new Product('Dune Art', [s1, s2, s3, s4, s5, s6]),
  new Product('Timber Breeze', [x1, x2, x3, x4, x5, x6, x7, x8, x9, x10]),
  new Product('Waw Wave', [r1, r2, r3, r4, r5, r6, r7, r8, r9, r10]),
 



];

// Home Page Carousel Image Paths
export const homePageImgPaths = [z1, k1, a2, c1, r1, m1, v1, y1, x1];

export function getProductByName(name) {
  return productList.find((product) => product.name === name);
}
